import React from 'react';
import { NavLink } from 'react-router-dom';
import Logo from '../resources/img/siemens-ag-logo.svg';
import '../resources/css/nav-bar.css';
import { UserService } from '../services/UserService';
import MdPower from 'react-icons/lib/md/power-settings-new';
import MdAccount from 'react-icons/lib/md/account-circle';
import MdSettings from 'react-icons/lib/md/settings';
import MdLanguage from 'react-icons/lib/md/language';
import MdCancel from 'react-icons/lib/md/cancel';
import { Modal, ModalHeader } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import OidcProvider from "../services/OidcProvider";

const userService = userService == null ? new UserService() : userService;
const userRole = userService.getUserRole();
const isSupplier = userRole === 'supplier';
const isAdmin = userRole === 'admin';
const isAdminOrSupplier = isAdmin || isSupplier;

let languageText = localStorage.getItem('language') === "en-US" ? 'English (US)' : 'Deutsch (DE)';

const getUsername = () => {
  const oidc = new OidcProvider();
  const user = oidc.getUser();
  return user ? user.profile.name : '';
}

export class NavBar extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      languageModal: false,
      isSiemensAdmin: false,
      isSiemensOrSupplier: false,
      isSupplier: false,
      isSiemens: false
    }

  }

  componentDidMount() {
    this.getRole()
  }

  getRole = () => {

    const role = (JSON.parse(localStorage.getItem('oidc_user'))).role;

    switch (role) {
        case 'userSiemens':
            this.setState({ isSiemensOrSupplier: true })
            break; 
        case 'userSupplier':
            this.setState({ 
              isSupplier: true,
              isSiemensOrSupplier: true
             })
            break; 
        case 'userAdmin':
            this.setState({ 
              isSiemens: true,
              isSiemensAdmin: true 
             })
            break; 
    }

  }

  toogleMenu = () => {
    document.getElementById('navbarNavDropdown').style.display === 'none' || document.getElementById('navbarNavDropdown').style.display === '' ? document.getElementById('navbarNavDropdown').style.display = 'block' : document.getElementById('navbarNavDropdown').style.display = 'none';
  }

  toogleSettings = () => {
    document.getElementById('dropdown-menu-settings').style.display === 'none' || document.getElementById('dropdown-menu-settings').style.display === '' ? document.getElementById('dropdown-menu-settings').style.display = 'block' : document.getElementById('dropdown-menu-settings').style.display = 'none';
  }

  showModal = () => {
    document.getElementById('dropdown-menu-settings').style.display = 'none';
    this.setState({
      languageModal: true
    })
  }

  closeModal = () => {
    this.setState({
      languageModal: false
    })
  }

  handleKeyPress = (target) => {
    if (target.charCode == 27) {
      this.setState({
        languageModal: false
      })
    }
  }

  loggOff = () => {
    const oidc = new OidcProvider();
    oidc.signOut();
    localStorage.removeItem('oidc_user');
  }

  chooseLanguage = (e) => {
    e.target.innerText === "ENGLISH" ? localStorage.setItem('language', 'en-US') : localStorage.setItem('language', 'de-DE');
    window.location.reload();
  }

  render() {
    return (
      <div>
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark" id="navBarId">
          <div>
            <img src={Logo} className="nav-logo" alt="logo" />
            <p className='navbar-brand'>&nbsp; | &nbsp;CertBlock</p>
            <h6 style={{ color: '#FFF' }}>
              {localStorage.getItem('oidc_user') ? JSON.parse(localStorage.getItem('oidc_user')).name : ''} &nbsp; | &nbsp; <FormattedMessage id="navBar.authAs" /> {getUsername()}
            </h6>
          </div>
          <button className="navbar-toggler" type="button" onClick={this.toogleMenu} data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse justify-content-end" id="navbarNavDropdown">
            <ul className="navbar-nav">
              {this.state.isSupplier &&
                <li className="nav-item">
                  <NavLink to="/create-certificate" className="nav-link" href="#" reloadDocument><FormattedMessage id="navBar.createCertificate" /></NavLink>
                </li>
              }
              {this.state.isSiemensAdmin &&
                <li className="nav-item">
                  {/* <NavLink to="/dashboard" className="nav-link" href="#">Dashboard</NavLink> */}
                </li>
              }
              {(this.state.isSiemensOrSupplier || this.state.isSiemensAdmin) &&
                <li className="nav-item">
                  <NavLink to="/show-certificate" className="nav-link" href="#" reloadDocument><FormattedMessage id="navBar.showCertificate" /></NavLink>
                </li>
              }
              {/* {isSupplier &&
            <li className="nav-item">
              <NavLink to="/create-supplier" className="nav-link" href="#">Create Supplier</NavLink>
            </li>
          } */}
              {/* <li className="nav-item">
            <NavLink to="/create-consignee" className="nav-link" href="#">Create Consignee</NavLink>
          </li> */}
              {this.state.isSiemensAdmin &&
                <li className="nav-item">
                  <NavLink to="/retrieve-companies" className="nav-link" href="#" reloadDocument>Pending Companies</NavLink>
                </li>
              }
              {(this.state.isSiemensOrSupplier || this.state.isSiemensAdmin) &&
                <li className="nav-item">
                  <NavLink to="/upload-documents" className="nav-link" href="#" reloadDocument><FormattedMessage id="navBar.uploadDocuments" /></NavLink>
                </li>
              }              
              <li className="nav-item">
                {/* <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Dropdown
            </a> */}
                <a className="nav-link" onClick={this.toogleSettings} aria-haspopup="true" aria-expanded="false">&nbsp;<MdAccount size={20} /></a>
                <div id="dropdown-menu-settings" aria-labelledby="navbarDropdown">
                  {this.state.isSupplier &&
                    <NavLink to="/profile" title="Profile" onClick={this.toogleSettings} className="dropdown-item" href="#" reloadDocument><MdSettings size={20} />&nbsp;<FormattedMessage id="navBar.settings" /></NavLink>
                  }
                  <a className="dropdown-item" href="#" id="languageMenu" onClick={this.showModal}><MdLanguage size={20} />&nbsp;{languageText}</a>
                  <div className="dropdown-divider" />
                  <NavLink to="/logoff" title="Logoff" onClick={this.loggOff} className="dropdown-item" href="#" reloadDocument><MdPower size={20} />&nbsp; <FormattedMessage id="navBar.signOut" /></NavLink>
                </div>
              </li>
              {/* <li className="nav-item">
            <NavLink to="/create-pdf" className="nav-link" href="#">PDF</NavLink>
          </li> */}
            </ul>
          </div>
        </nav>
        <div onKeyPress={this.handleKeyPress}>
          <Modal animation={false} show={this.state.languageModal}>
            <div className="d-flex justify-content-end">
              <a style={{ marginRight: 5 }} onClick={this.closeModal} aria-haspopup="true" aria-expanded="false">&nbsp;<MdCancel /></a>
            </div>
            <ModalHeader>
              <Modal.Title><FormattedMessage id="commonMessages.setLanguage" /></Modal.Title>
            </ModalHeader>
            <Modal.Body>
              <div className="list-group">
                <a className="list-group-item list-group-item-action" value="en-US" href="#" onClick={this.chooseLanguage}>ENGLISH</a>
                <a className="list-group-item list-group-item-action" value="de-DE" href="#" onClick={this.chooseLanguage}>DEUTSCH</a>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    )
  }
}

export default NavBar