import React from 'react';
import FormErrors from '../components/FormErrors';
import { Modal, Button, ModalHeader } from 'react-bootstrap';
import LoadingComponent from '../components/LoadingComponent';
import { LinkContainer } from 'react-router-bootstrap';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import UserService from '../services/UserService';
import { FormattedMessage } from 'react-intl';
import Messages from '../messages';
import {MdAdd, MdAttachFile} from 'react-icons/lib/md';
import {AsyncTypeahead} from 'react-bootstrap-typeahead';

import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Footer from '../components/Footer';

import OidcProvider from '../services/OidcProvider';
import NavBar from '../components/NavBar';

import { ApiService } from '../services/ApiService';
import axios from 'axios';

const globalMessages = Messages;
const userService = userService == null ? new UserService() : userService;
const userRole = userService.getUserRole();
const isSupplier = userRole === 'userSupplier';
const apiFlagREADY_FOR_UPLOAD = 'READY_FOR_UPLOAD';

let rowItemkey = 0;
const certblockAPI = new ApiService();

export class UploadDocumentsContainer extends React.Component {

    constructor(props) {

        super(props);
        this.apiConfig = window.ApiConfig;
        this.deliveryNumberInput = React.createRef();

        this.state = {
            isSiemensOrSupplier: true,
            certificateId: '',
            supplier: '',
            orderNumber: '',
            deliveryNumber: '',
            orderPosition: '',
            materialNumber: '',
            materialDescription: '',
            quantity: '',
            serialNumber: '',
            overviewTests: '',
            nameInspector: '',
            inspectionDate: moment(),
            confirmationText: '',
            confirmation: '',
            checkTestsResults: '',
            materialList: [],
            certificateList: [],
            supplierData: {
                name: '',
                street: '',
                zip: '',
                city: '',
                country: ''
            },
            consigneeIndex: null,
            consigneeData: {
                name: '',
                street: '',
                zip: '',
                city: '',
                country: ''
            },
            expandedRows: [],
            showModal: false,
            showLoadingModal: false,
            supplierValid: true,
            orderNumberValid: false,
            deliveryNumberValid: false,
            orderPositionValid: true,
            materialNumberValid: false,
            materialDescriptionValid: false,
            quantityValid: false,
            serialNumberValid: true,
            overviewTestsValid: true,
            nameInspectorValid: false,
            inspectionDateValid: true,
            confirmationTextValid: false,
            confirmationValid: false,
            formValid: false,
            formMaterialValid: false,
            setPlaceHolderValue: false,
            formErrors: {
                supplier: '',
                orderNumber: '',
                deliveryNoteNumber: '',
                deliveryNumber: '',
                orderPosition: '',
                materialNumber: '',
                materialDescription: '',
                quantity: '',
                serialNumber: '',
                overviewTests: '',
                nameInspector: '',
                inspectionDate: '',
                confirmationText: '',
                confirmation: ''
            },
            deliveryNoteNumber: '',
            defaultDeliveryNoteNumber: '',
            orderNumbersForSupplier: [],
            materials: [],
            documentProfiles: [],
            orderPositionSelected: '',
            supplierNumber: '',
            decryptError: false,
            selectedFiles: {},
            isLoadingPrefix: false
        };

    }

    setSupplierNumber() {
        const IAM_API = certblockAPI.getIAM_API(this.getUser().id_token);
        IAM_API.userCurrent()
        .then((certblockUser) => {
            this.setState({
                // testerList: certblockUser.data.testers ? certblockUser.data.testers : [],
                // tests: certblockUser.data.materialTests ? certblockUser.data.materialTests : [],
                supplierNumber: certblockUser.data.supplier ? certblockUser.data.supplier : ''
            });
        }).catch((error) => {
            this.setState({
                showAPIErrorModal: true,
                apiErrorMessage: globalMessages[localStorage.getItem('language')].createCertificate.uiErrorMessageFailAPICall
            });
        });

    }

    
    componentDidMount() {
        this.getRole();
        // this.getDirectLinkCertificate();
        this.setSupplierNumber();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.supplierNumber !== '' && this.state.supplierNumber !== prevState.supplierNumber) {
            this.getOrderNumbers(this.state.supplierNumber);
        }
    }    

    getDirectLinkCertificate = () => {


        const oNr = sessionStorage.getItem("orderNumber");
        console.log(`DEBUG: [orderNumberFromStaticLink] ${oNr}`)
        const oPos = sessionStorage.getItem("orderPosition");
        const dBr = sessionStorage.getItem("deliveryNumber");
        if (oNr && oPos && dBr) {
            this.setState({
                showLoadingModal: true
            });
          
            const CRT_API = certblockAPI.getCRT_API(this.getUser().id_token);
            CRT_API.getCertificateId(oNr, oPos, dBr)
                .then((certificate) => {
                    this.setState({
                        showLoadingModal: false
                    });

                    sessionStorage.removeItem("orderNumber");
                    sessionStorage.removeItem("orderPosition");
                    sessionStorage.removeItem("deliveryNumber");

                    this.showCertificate(certificate.data, 0);
                })
                .catch(() => {
                    this.setState({
                        showLoadingModal: false
                    });

                    sessionStorage.removeItem("orderNumber");
                    sessionStorage.removeItem("orderPosition");
                    sessionStorage.removeItem("deliveryNumber");
                    console.error('Certificate not found');
                });
        }

    };

    getRole = () => {
        const oidc = new OidcProvider();
        const user = oidc.getUser();

        if (user.role === 'userSupplier') {
            this.setState({ isSupplier: true });
        }

    }

    handleKeyUp = (e) => {
        if (e == null || e.target.name !== "orderNumber") return;

        switch (e.key) {
            case "Enter":
            case "Return": {
                this.getCertificates();
                break;
            }
        }
    };


    handleChange = (e) => {
        let name;
        let value;

        if (e != null) {

            name = e._isAMomentObject ? "inspectionDate" : e.target.name;
            value = e._isAMomentObject ? e : (e.target.type === "checkbox" ? e.target.checked : e.target.value);

            switch (name) {
                case "orderNumber": {
                    this.setState({ orderNumber: value });
                    break;
                }
                case "deliveryNoteNumber": {
                    this.setState({ deliveryNoteNumber: value},()=>{
                        console.log('defaultDeliveryNoteNumber:' + this.state.defaultDeliveryNoteNumber);
                        console.log('deliveryNoteNumber:' + this.state.deliveryNoteNumber);
                    });
                    break;
                }
                case "setPlaceHolderValue": {
                    let today = new Date();
                    let myDate =  today.getFullYear() 
                                    + ('0' + (today.getMonth()+1)).slice(-2) 
                                    + ('0' + today.getDate()).slice(-2);
                    this.setState({setPlaceHolderValue: value},()=>{
                        if(this.state.setPlaceHolderValue) {
                            this.setState({
                                defaultDeliveryNoteNumber: myDate
                            },()=>{
                                console.log('defaultDeliveryNoteNumber:' + this.state.defaultDeliveryNoteNumber);
                                console.log('deliveryNoteNumber:' + this.state.deliveryNoteNumber);
                            })
                        } else {
                            this.setState({
                                defaultDeliveryNoteNumber: ''
                            },()=>{
                                console.log('defaultDeliveryNoteNumber:' + this.state.defaultDeliveryNoteNumber);
                                console.log('deliveryNoteNumber:' + this.state.deliveryNoteNumber);
                            })
                        }
                    });
                    break;
                }
                default: {
                    break;
                }
            }
        }
    };

 

    handleClose = () => {
        this.setState({ showModal: false });
    };

    getUser = () => {
        return JSON.parse(localStorage.getItem('oidc_user'));
    };

    getCertificates = () => {
        this.setState({
            showLoadingModal: true
        });

        const CRT_API = certblockAPI.getCRT_API(this.getUser().id_token);

        CRT_API.getCertificateIdList(this.state.orderNumber)
        .then((certificates) => {
            console.log(`[certificateGet] ${JSON.stringify(certificates.data)}`);
            this.setState({
                certificateList: certificates.data,
                showLoadingModal: false
            });
        })
        .catch((error) => {
            console.error(error);
            this.setState({
                showLoadingModal: false
            });
        });

    };

    showCertificate = (id, version) => {

        this.setState({
            showLoadingModal: true
        });

        console.log(`DEBUG: [id/version] ${id}/${version}`);
        const CRT_API = certblockAPI.getCRT_API(this.getUser().id_token);

        CRT_API.getCertificateSiemensById(id, version)
        .then((certificate) => {
            this.setState({
                showLoadingModal: false,
                showModal: true,
                supplierData: certificate.data.creator,
                orderNumber: certificate.data.orderNumber,
                deliveryNumber: certificate.data.deliveryNumber,
                materialList: certificate.data.materialList,
                nameInspector: certificate.data.nameInspector,
                inspectionDate: certificate.data.inspectionDate,
                confirmationText: certificate.data.confirmationText,
                confirmation: certificate.data.checkConfirmation,
                checkTestsResults: certificate.data.checkTestsResults,
                consigneeData: certificate.data.consignee,
                orderPosition: certificate.data.materialList[0].orderPosition
            });
        })
        .catch((error) => {
            console.error(error);
            this.setState({
                showLoadingModal: false
            });
        });
    
    };

handleFileChange = (event) => {
    console.log('DEBUG[handleFileChange]');
    let currentFiles = this.state.selectedFiles;
    let position = event.target.name;
    currentFiles[position] = event.target.files[0];
    this.setState({selectedFiles: currentFiles},()=>{
        console.log(this.state.selectedFiles);
    });
}

errorClass = (error) => {
    return (error.length === 0 ? '' : 'is-invalid');
};


handleChangeOrderNumber = (value) => {
    console.log(`DEBUG  [handleChangeOrderNumber] value: ${value}`);
    this.getMaterials(value,(returnedMaterials)=>{
        this.setState({
            orderNumber: value,
            materials: returnedMaterials,
            selectedFiles: {},
            orderPositionSelected: '',
        },()=>{
            // console.log(this.state.materials);
        });
    })
}   

handleGetDocumentProfiles = (material, orderPosition) => {
    console.log(`DEBUG  [handleGetDocumentProfiles] material:${material} orderPosition:${orderPosition}`);
    console.log(`DEBUG  [handleGetDocumentProfiles] deliveryNoteNumber:${this.state.deliveryNoteNumber}`);
    if (this.state.orderPositionSelected == orderPosition) { //Closing the '+' sign
        this.setState({
            documentProfiles: [],
            orderPositionSelected: '',
            selectedFiles: {},
        });
        return;
    }

    this.getDocumentProfiles(material, orderPosition, (profiles)=>{
        this.setState({
            documentProfiles: profiles,
            orderPositionSelected: orderPosition,
            selectedFiles: {}, 
        },()=>{
            console.log(this.state.orderPositionSelected);
            console.log(this.state.documentProfiles);
        });
    })
}

    async getDocumentProfiles(material,orderPosition, callback){
        console.log(`DEBUG [getDocumentProfiles] material: ${material} orderPosition:${orderPosition}`);
        // const CRT_API = certblockAPI.getCRT_API(this.getUser().id_token);
        let deliveryNoteNumber = this.state.defaultDeliveryNoteNumber ?? this.state.deliveryNoteNumber; 
        try {
            let response = await certblockAPI.getDocumentProfiles(material, orderPosition, deliveryNoteNumber, callback);
            return response;

        }catch(error){
            console.log('ERROR: ' + JSON.stringify(error));
            this.setState({
                showAPIErrorModal: true,
                apiErrorMessage: apiError
            });
        }
    }

    async getMaterials(value, callback) {
        console.log('DEBUG [getMaterials]')
        // const CRT_API = certblockAPI.getCRT_API(this.getUser().id_token);
        try {
            let response = await certblockAPI.getMaterials(value,callback);
            return response;
        } catch(error) {
            console.log('ERROR: ' + JSON.stringify(error));
            this.setState({
                showAPIErrorModal: true,
                apiErrorMessage: apiError
            });
        }
    }

    async setUploadSlot(orderNumber, orderPosition, profileId){
        console.log(`DEBUG[setUploadSlot] (orderNumber, orderPosition, profileId): (${orderNumber}, ${orderPosition}, ${profileId})`);
        let deliveryNoteNumber = this.state.defaultDeliveryNoteNumber ?? this.state.deliveryNoteNumber; 
            try {
                let request = await certblockAPI.callApi(
                    'setUploadSlots',
                    [orderNumber, deliveryNoteNumber, orderPosition],
                    'post',
                    {'profileId' : profileId},
                )
                return request;
            }catch(error){
                console.log('DEBUG[setUploadSlot] Error:');
                console.log(error.message);
                this.setState({
                    showAPIErrorModal: true,
                    apiErrorMessage: error.message
                });                
        }
    }

    async getUploadSlots(orderNumber, orderPosition, profiles )
    {
        console.log('DEBUG[getUploadSlots]');
        let slots = {};
        try {
            await Promise.all(profiles.map(async profileId => {
                let slot = await this.setUploadSlot(orderNumber, orderPosition, profileId);
                slots[profileId]=slot.data;
            }));
            console.log(slots);
        } catch(error){
            console.log('DEBUG[getUploadSlots] ERROR:');
            console.log(error)
        }
        return slots;
    }
    
    async saveDocumentsProfiles (orderNumber, orderPosition) { 
        console.log('DEBUG[saveDocumentsProfiles]');
        console.log(this.state.selectedFiles);
        let profileIds = Object.keys(this.state.selectedFiles);
        //getting files slots
        let slots = await this.getUploadSlots(orderNumber, orderPosition, profileIds);
        let deliveryNoteNumber = this.state.defaultDeliveryNoteNumber ?? this.state.deliveryNoteNumber; 
        //saving files to the slots
        for (const [key, value] of Object.entries(this.state.selectedFiles)) {
            console.log('DEBUG[saveDocumentsProfiles] saving..');
            console.log(value);
            console.log(`url: ${slots[key].presignedUrl}`);
            if (this.state.selectedFiles[key] && slots[key].presignedUrl) {
                
                let response = await certblockAPI.callApi(
                    'uploadFile',
                    [deliveryNoteNumber],
                    // [],
                    'put',
                    '',
                    {},
                    slots[key].presignedUrl,
                    value
                );
                console.log(response);
            }
          }
          for (const [key, value] of Object.entries(this.state.selectedFiles)) {
            console.log('DEBUG[saveDocumentsProfiles] polling..');
            if (this.state.selectedFiles[key] && slots[key].presignedUrl) {
                console.log(`(orderNumber,orderPosition,slotId): ${orderNumber}, ${orderPosition}, ${slots[key].slotId}`);
                let response = await certblockAPI.callApi(
                    'pollingFiles',
                    [orderNumber, deliveryNoteNumber, orderPosition, slots[key].slotId],
                    'get'
                );
                console.log(response.data);
                slots[key].pollingResult = response.data.status;
                console.log(slots[key]);
                // data: {
                // created: "1730386898"
                // docuProfileId: "000015"
                // orderNumber: "3204228668"
                // orderPosition: "00010"
                // slotId: "c521416a-9575-4924-943b-2ad0a9ed758c"
                // status: "READY_FOR_UPLOAD"
                // updated: "1730386898"}
          } 

            //  the docuprofiles assignment only for those with "READY_FOR_UPLOAD" status
            // /orders/{orderNumber}/material/{orderPosition}/documentProfiles/{docuProfileId} with the payload
            // {
            //   "slotId": "a3548008-ace8-40d1-ab1c-00cff500b158" // sample slot id
            // } 

          for (const [key, value] of Object.entries(this.state.selectedFiles)) {
            console.log('DEBUG[saveDocumentsProfiles] assignment..');
            if (this.state.selectedFiles[key] && slots[key].presignedUrl && slots[key].pollingResult == apiFlagREADY_FOR_UPLOAD) {
                console.log(`(orderNumber,orderPosition, docuProfileId, slotId): ${orderNumber}, ${orderPosition}, ${key}, ${slots[key].slotId}`);
                let response = await certblockAPI.callApi(
                    'profileDocsAssignment',
                    [orderNumber, deliveryNoteNumber, orderPosition, key],
                    'put',
                    {'slotId': slots[key].slotId}
                );
                console.log(response);
                slots[key].assignResponse = {
                    message: response.data.message,
                    path: response.data.path
                }
            }
          }
          console.log(slots);
        }
    }

    getOrderNumbersPrefix = async (orderNumberPrefix) => {
        console.log(` MARWAL DEBUG[getOrderNumbersPrefix] prefix: ${orderNumberPrefix} supplier: ${this.state.supplierNumber}`);
        // const CRT_API = certblockAPI.getCRT_API(this.getUser().id_token);
        this.setState({isLoadingPrefix: true});
        try {
            let supplierNumber = this.state.supplierNumber;
            if(supplierNumber) {
                this.setState({isLoadingPrefix: false});
                return (await certblockAPI.callApi('orderPrefix', [supplierNumber, orderNumberPrefix])).data;
            }
        } catch (e) {
            console.log('MARWAL DEBUG[getOrderNumbersPrefix] Error:');
            console.log(e);
            this.setState({isLoadingPrefix: false});
            // this.setState({
            //     showAPIErrorModal: true,
            //     apiErrorMessage: globalMessages[localStorage.getItem('language')].createCertificate.uiErrorMessageFailAPICall
            // });
        }
        return [];
    }

    getOrderNumbers = async (supplierNumber) => {
        console.log(` MARWAL DEBUG[getOrderNumbers] supplier: ${supplierNumber}`);
        let deliveryNoteNumber = this.state.defaultDeliveryNoteNumber ?? this.state.deliveryNoteNumber; 
        // const CRT_API = certblockAPI.getCRT_API(this.getUser().id_token);
        try {
            let ordersList = (await certblockAPI.callApi('orders', [supplierNumber, deliveryNoteNumber])).data; //
            // let ordersList = (await certblockAPI.callApi('orders', [supplierNumber])).data;
            if(!ordersList) {
                this.setState({
                    showAPIErrorModal: true,
                    apiErrorMessage: globalMessages[localStorage.getItem('language')].createCertificate.uiErrorMessageFailAPICall
                });
                return;
            }

            if (ordersList.length > 0) {
                this.setState({ orderNumbersForSupplier: ordersList });
                console.log('order(s) found ... create your certificate')
            } else {
                this.setState({ showAPIErrorModal: true, apiErrorMessage: globalMessages[localStorage.getItem('language')].createCertificate.uiErrorMessageFailAPICall });
            }
        } catch (e) {
            this.setState({
                showAPIErrorModal: true,
                apiErrorMessage: globalMessages[localStorage.getItem('language')].createCertificate.uiErrorMessageFailAPICall
            });
            return;
        }
    }

//--------------------------------


    generatePDF = () => {

        const pdfSupplier = document.getElementById('pdfSupplierInfo').innerText;
        const pdfConsignee = document.getElementById('pdfConsigneeInfo').innerText;
        const pdfOrderNumber = document.getElementById('orderNumberInput').value;
        const pdfDeliveryNumber = document.getElementById('deliveryNumberInput').value;
        const pdfItemMaterialList = Array.from(document.querySelectorAll(".pdfItemMaterialList"));
        const pdfInspectorName = document.getElementById('nameInspectorInput').value;
        const pdfInspectionDate = document.getElementById('inspectionDateInput').value;
        const pdfNoteTestsResultsText = document.getElementById('noteTestsText').innerText;
        const pdfConfirmationText = document.getElementById('confirmationText').innerText;
        const pdfNoteTestsResults = document.getElementById('noteTestResultsCheckBox').checked ? 'Agreed by signer' : 'Not agreed by signer';
        const pdfConfirmation = document.getElementById('confirmationCheckBox').checked ? 'Agreed by signer' : 'Not agreed by signer';

        // init

        const pdf = new jsPDF({
            orientation: 'landscape',
            unit: 'pt',
            format: 'a4'
        });

        //Title
        pdf.setFontSize(25);
        pdf.setTextColor(100);
        pdf.text('Inspection Certificate 3.1 acc. EN 10204', 40, 50);

        //Supplier info

        let colHeaderSupplier = [
            { title: "Supplier", dataKey: "text" }
        ];

        let rowHeaderSupplier = [];
        rowHeaderSupplier.push(
            { text: pdfSupplier }
        );

        pdf.autoTable(colHeaderSupplier.slice(0, 2), rowHeaderSupplier, {
            startY: 90,
            theme: 'plain',
            styles: { textColor: 125, halign: 'left', overflow: 'linebreak', columnWidth: 'wrap' },
            columnStyles: { text: { columnWidth: 'auto' } },
            margin: { right: 240 }
        });

        // Reset page to the same as before previous table

        pdf.setPage(1 + pdf.internal.getCurrentPageInfo().pageNumber - pdf.autoTable.previous.pageCount);

        //Consignee info

        let colHeaderConsignee = [
            { title: "Consignee", dataKey: "text" }
        ];

        let rowHeaderConsignee = [];
        rowHeaderConsignee.push(
            { text: pdfConsignee }
        );

        pdf.autoTable(colHeaderConsignee.slice(0, 2), rowHeaderConsignee, {
            startY: 90,
            theme: 'plain',
            styles: { textColor: 125, halign: 'left', overflow: 'linebreak', columnWidth: 'wrap' },
            columnStyles: { text: { columnWidth: 'auto' } },
            margin: { left: 360 }
        });

        let nextOutputY = pdf.autoTable.previous.finalY + 10;

        //OrderNumber info

        let colHeaderOrderNumber = [
            { title: "Delivery Note", dataKey: "text" }
        ];

        let rowHeaderOrderNumber = [];
        rowHeaderOrderNumber.push(
            { text: pdfOrderNumber }
        );

        pdf.autoTable(colHeaderOrderNumber, rowHeaderOrderNumber, {
            startY: nextOutputY,
            theme: 'plain',
            styles: { textColor: 125, halign: 'left', overflow: 'linebreak', columnWidth: 'wrap' },
            columnStyles: { text: { columnWidth: 'auto' } },
            margin: { right: 240 }
        });

        //DeliveryNumber info

        let colHeaderDeliveryNumber = [
            { title: "Delivery Note Number", dataKey: "text" }
        ];

        let rowHeaderDeliveryNumber = [];
        rowHeaderDeliveryNumber.push(
            { text: pdfDeliveryNumber }
        );

        pdf.autoTable(colHeaderDeliveryNumber, rowHeaderDeliveryNumber, {
            startY: nextOutputY,
            theme: 'plain',
            styles: { textColor: 125, halign: 'left', overflow: 'linebreak', columnWidth: 'wrap' },
            columnStyles: { text: { columnWidth: 'auto' } },
            margin: { left: 360 }
        });

        //Materials info

        let colMaterialList = ['Order Position', 'Quantity', 'Material Number', 'Material Name', 'Serial Number', 'Overview Tests'];
        let rowMaterialList = [];

        pdfItemMaterialList.forEach(line => {
            let contentList = [];
            let column = line.cells;
            for (let i = 0; i < column.length; i++) {
                const element = column.item(i).innerText;
                contentList.push(element);
            }
            rowMaterialList.push(contentList);
        });

        pdf.autoTable(colMaterialList, rowMaterialList, {
            startY: pdf.autoTable.previous.finalY + 10,
            styles: { overflow: 'linebreak', halign: 'left', valign: 'middle' },
            showHeader: 'firstPage'
        });

        //Inspector Name info

        let colFooterInspectorName = [
            { title: "Inspector Name", dataKey: "text" }
        ];

        let rowFooterInspectorName = [];
        rowFooterInspectorName.push(
            { text: pdfInspectorName }
        );

        pdf.autoTable(colFooterInspectorName.slice(0, 2), rowFooterInspectorName, {
            startY: pdf.autoTable.previous.finalY + 25,
            theme: 'plain',
            styles: { textColor: 125, halign: 'left', overflow: 'linebreak', columnWidth: 'wrap' },
            columnStyles: { text: { columnWidth: 'auto' } },
            margin: { right: 135 },
            showHeader: 'firstPage'
        });

        //InspectionDate info

        let colFooterInspectionDate = [
            { title: "Inspection Date", dataKey: "text" }
        ];

        let rowFooterInspectionDate = [];
        rowFooterInspectionDate.push(
            { text: pdfInspectionDate }
        );

        pdf.autoTable(colFooterInspectionDate.slice(0, 2), rowFooterInspectionDate, {
            startY: pdf.autoTable.previous.finalY - 45,
            theme: 'plain',
            styles: { textColor: 125, halign: 'left', overflow: 'linebreak', columnWidth: 'wrap' },
            columnStyles: { text: { columnWidth: 'auto' } },
            margin: { left: 465 },
            showHeader: 'firstPage'
        });

        //Note Tests Results Text

        let colFooterNoteTestsResults = [
            { title: "Note/Tests Results", dataKey: "text" }
        ];

        let rowFooterNoteTestsResults = [];
        rowFooterNoteTestsResults.push(
            { text: pdfNoteTestsResultsText }
        );

        pdf.autoTable(colFooterNoteTestsResults.slice(0, 2), rowFooterNoteTestsResults, {
            startY: pdf.autoTable.previous.finalY + 30,
            theme: 'plain',
            styles: { textColor: 125, halign: 'left', overflow: 'linebreak', columnWidth: 'wrap' },
            columnStyles: { text: { columnWidth: 'auto' } },
            margin: { right: 170 },
            showHeader: 'firstPage'
        });

        //Note Tests Results check

        // let colFooterNoteTestsResultsCheck = [
        //     { title: "", dataKey: "check" }
        // ];

        // let rowFooterNoteTestsResultsCheck = [];
        // rowFooterNoteTestsResultsCheck.push(
        //     { check: pdfNoteTestsResults }
        // );

        // pdf.autoTable(colFooterNoteTestsResultsCheck.slice(0, 2), rowFooterNoteTestsResultsCheck, {
        //     startY: pdf.autoTable.previous.finalY - 60,
        //     theme: 'plain',
        //     styles: { textColor: 125, halign: 'left', overflow: 'linebreak', columnWidth: 'wrap' },
        //     columnStyles: { text: { columnWidth: 'auto' } },
        //     margin: { left: 470 }
        // });

        //Confirmation Text

        let colFooterConfirmation = [
            { title: "Confirmation", dataKey: "text" }
        ];

        let rowFooterConfirmation = [];
        rowFooterConfirmation.push(
            { text: pdfConfirmationText }
        );

        pdf.autoTable(colFooterConfirmation.slice(0, 2), rowFooterConfirmation, {
            startY: pdf.autoTable.previous.finalY + 20,
            theme: 'plain',
            styles: { textColor: 125, halign: 'left', overflow: 'linebreak', columnWidth: 'wrap' },
            columnStyles: { text: { columnWidth: 'auto' } },
            margin: { right: 170 },
            showHeader: 'firstPage'
        });

        //Confirmation check

        // let colFooterConfirmationCheck = [
        //     { title: "", dataKey: "check" }
        // ];

        // let rowFooterConfirmationCheck = [];
        // rowFooterConfirmationCheck.push(
        //     { check: pdfConfirmation }
        // );

        // pdf.autoTable(colFooterConfirmationCheck.slice(0, 2), rowFooterConfirmationCheck, {
        //     startY: pdf.autoTable.previous.finalY - 60,
        //     theme: 'plain',
        //     styles: { textColor: 125, halign: 'left', overflow: 'linebreak', columnWidth: 'wrap' },
        //     columnStyles: { text: { columnWidth: 'auto' } },
        //     margin: { left: 470 }
        // });


        // FinalNote: "This certificate was created electronically and is valid without signature."

        let pdfFinalNote = "This certificate was created electronically and is valid without signature.";
        let colHeaderFinalNote = [
            { title: "", dataKey: "text" }
        ];

        let rowHeaderFinalNote = [];
        rowHeaderFinalNote.push(
            { text: pdfFinalNote }
        );

        pdf.autoTable(colHeaderFinalNote, rowHeaderFinalNote, {
            startY: pdf.autoTable.previous.finalY + 20,
            theme: 'plain',
            styles: { textColor: 125, overflow: 'linebreak', halign: 'center', valign: 'middle' },
            columnStyles: { text: { columnWidth: 'auto' } },
        });



        // Generating pdf

        // pdf-filename: "<date>_<supplierName>_<orderNumber>_<deliveryNumber>_3_1_Zertifikat.pdf"
        let dateYYYYMMDD = moment.unix(this.state.inspectionDate).format("YYYYMMDD");
        let pdfFileName = `${dateYYYYMMDD}_${this.state.supplierData.name}_${pdfOrderNumber}_${pdfDeliveryNumber}_3_1_Zertifikat.pdf`;

        pdf.save(pdfFileName);

    };

    handleRowClick = (rowId) => {
        const currentExpandedRows = this.state.expandedRows;
        const isRowCurrentlyExpanded = currentExpandedRows.includes(rowId);

        const newExpandedRows = isRowCurrentlyExpanded ?
            currentExpandedRows.filter(id => id !== rowId) :
            currentExpandedRows.concat(rowId);

        document.getElementById("materialBtn-" + rowId).innerHTML === globalMessages[localStorage.getItem('language')].showCertificate.btnShowPositions ?
            document.getElementById("materialBtn-" + rowId).innerHTML = globalMessages[localStorage.getItem('language')].showCertificate.btnHidePositions :
            document.getElementById("materialBtn-" + rowId).innerHTML = globalMessages[localStorage.getItem('language')].showCertificate.btnShowPositions;

        this.setState({ expandedRows: newExpandedRows });
    };

    renderItem = (item) => {

        const clickCallback = () => this.handleRowClick(item.certificateId);
        const itemRows = [
            <tr key={"row-data-" + item.certificateId + moment.unix(item.inspectionDate).format("DDMMYYYYhh:mm:ssa")}>
                <td>{item.orderNumber}</td>
                <td>{item.deliveryNumber}</td>
                <td>{item.materialList.length}</td>
                <td>{moment.unix(item.inspectionDate).format("DD/MM/YYYY")}</td>
                <td>
                    <button type="button" id={"materialBtn-" + item.certificateId} value={item.certificateId} onClick={clickCallback} className="btn btn-primary">{globalMessages[localStorage.getItem('language')].showCertificate.btnShowPositions}</button>
                </td>
                <td>
                    <button type="button" data-toggle="modal" data-target="#modal" name="showCertificateBtn" value={(item.certificateId, item.currentVersion)} onClick={this.showCertificate.bind(this, item.certificateId, item.currentVersion)} className="btn btn-primary"><FormattedMessage id="showCertificate.formTableButtonShowCertificate" /></button>
                </td>
            </tr >
        ];

        if (this.state.expandedRows.includes(item.certificateId)) {
            itemRows.push(
                <tr className="table-dark" key={"row-item-title" + item.certificateId + moment.unix(item.inspectionDate).format("DDMMYYYYhh:mm:ssa")}>
                    <td style={{ borderTopWidth: 0 }}>{globalMessages[localStorage.getItem('language')].showCertificate.modalOrderPosition}</td>
                    <td style={{ borderTopWidth: 0 }} colSpan="2">{globalMessages[localStorage.getItem('language')].showCertificate.modalMaterialNumber}</td>
                    <td style={{ borderTopWidth: 0 }} colSpan="2">{globalMessages[localStorage.getItem('language')].showCertificate.modalMaterialName}</td>
                </tr>
            );
            item.materialList.forEach((material) => {
                itemRows.push(
                    <tr key={"row-item-" + rowItemkey}>
                        <td>{material.orderPosition}</td>
                        <td colSpan="2" style={{ wordWrap: 'break-word', minWidth: 100, maxWidth: 160 }}>{material.materialNumber}</td>
                        <td colSpan="2" style={{ wordWrap: 'break-word', minWidth: 100, maxWidth: 160 }}>{material.materialDescription}</td>
                        <td>@MARWAL @TODO</td>
                    </tr>
                );
                rowItemkey++;
            });
        }

        return itemRows;
    };

    render = () => {
        let allItemRows = [];

        this.state.certificateList.forEach(item => {
            const perItemRows = this.renderItem(item);
            allItemRows = allItemRows.concat(perItemRows);
        });

        return (
            <div>
                <NavBar />
                <FormErrors formErrors={this.state.formErrors} />
                {this.state.isSiemensOrSupplier &&
                    <div className="container col-xl-6 col-lg-9 col-md-12 col-sm-12">
                        {/* <form onSubmit={this.handleSubmit}>     --> not needed here and would prevent that "handleKeyUp"-event is executed/handled!*/}
                        <h2><FormattedMessage id="uploadDocuments.formTitle" /></h2>

                        <div className="card" style={{ padding: 20, marginTop: 30 }}>
                            <div className="form-row">
                                <div className="form-group col-md-9 col-sm-12">
                                    <label htmlFor="deliveryNoteNumberInput"><FormattedMessage id="uploadDocuments.formDeliveryNoteNumber" /></label>
                                    <input name="deliveryNoteNumber" type="text" 
                                        onChange={this.handleChange} 
                                        onKeyUp={this.handleKeyUp} 
                                        className="form-control" id="deliveryNoteNumberInput" 
                                        placeholder={globalMessages[localStorage.getItem('language')].uploadDocuments.formDeliveryNoteNumberPlaceholder} 
                                        disabled={this.state.setPlaceHolderValue}/>
                                    <div className="invalid-feedback"><FormattedMessage id="showCertificate.formOrderNumber" /> {this.state.formErrors.orderNumber}</div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-9 col-sm-12">
                                    <label>
                                        <input name='setPlaceHolderValue' type="checkbox" checked={this.setPlaceHolderValue} onChange={this.handleChange} />
                                        <FormattedMessage id="uploadDocuments.formCheckboxSetPlaceHolderValue" /> 
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className="card" style={{ padding: 20, marginTop: 30 }}>
                            <div className="form-row">
                                <div className="form-group col-md-9 col-sm-12">
                                    <label htmlFor="orderNumberInput"><FormattedMessage id="uploadDocuments.formOrderNumber" /></label>
                                    <AsyncTypeahead
                                            onChange={this.handleChangeOrderNumber}
                                            id="orderNumberInput"
                                            isLoading={this.state.isLoadingPrefix}
                                            value={this.state.orderNumber}
                                            inputProps={{ className: `form-control ${this.errorClass(this.state.formErrors.orderNumber)}` }}
                                            placeholder={globalMessages[localStorage.getItem('language')].createCertificate.formOrderNumberPlaceHolder}
                                            options={this.state.orderNumbersForSupplier}
                                            onSearch={this.getOrderNumbersPrefix}/>
                                    {/* <input name="orderNumber" type="text" onChange={this.handleChange} onKeyUp={this.handleKeyUp} className="form-control" id="orderNumberInput" placeholder={globalMessages[localStorage.getItem('language')].uploadDocuments.formOrderNumberPlaceHolder} /> */}
                                    <div className="invalid-feedback"><FormattedMessage id="uploadDocuments.formOrderNumber" /> {this.state.formErrors.orderNumber}</div>
                                </div>
                                {/* <div className="form-group col-md-3 col-sm-12 d-flex justify-content-center" style={{ marginTop: 31 }}>
                                    <button type="button" onClick={this.getCertificates} className="btn btn-primary" style={{ whiteSpace: 'normal' }}><FormattedMessage id="uploadDocuments.formShowCertificatesBtn" /></button>
                                </div> */}
                            </div>
                        </div>

                        <div className="card" style={{padding: 20, marginTop: 30}}>
                            <div id="normalMode">
                                <label htmlFor="orderNumberInput"><FormattedMessage id="createCertificate.formMaterialFromOrderNum" /></label>                                
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col"><FormattedMessage id="uploadDocuments.formOrderPosition" /></th>
                                            <th scope="col"><FormattedMessage id="uploadDocuments.formQuantity" /></th>
                                            <th scope="col"><FormattedMessage id="uploadDocuments.formMaterialNumber" /></th>
                                            <th scope="col"><FormattedMessage id="uploadDocuments.formMaterialName" /></th>
                                            <th scope="col"><FormattedMessage id="uploadDocuments.formProfiles" /></th>
                                            <th scope="col"><FormattedMessage id="uploadDocuments.formAttachments" /></th>
                                            {/* <th scope="col"><FormattedMessage id="uploadDocuments.status" /></th> */}
                                            <th scope="col" />
                                        </tr>
                                    </thead>  
                                    <tbody>
                                        {this.state.materials.map((material, index) =>{
                                            return (
                                                <tr key={material.orderPosition} className="pdfItemMaterialList">
                                                    <td>{material.orderPosition}</td>
                                                    <td>{material.quantity}</td>
                                                    <td>{material.materialNumber}</td>
                                                    <td>{material.materialDescription}</td>
                                                    <td>
                                                        <button type="button" value={index} onClick={()=>{this.handleGetDocumentProfiles(this.state.orderNumber, material.orderPosition)}} id={'addButton' + index} className="btn btn-default btnDeleteMaterial" disabled={false} style={{ padding: 0 }}>
                                                            <MdAdd size={25} />
                                                        </button>
                                                    </td>
                                                    <td>
                                                        {this.state.orderPositionSelected == material.orderPosition  
                                                        && this.state.documentProfiles.map((profile, idx)=>{
                                                            return (     
                                                                <form key={idx}>
                                                                        <label className="btn btn-primary" htmlFor={profile.profileNumber} >{profile.profileNumber}<MdAttachFile size="1.25em"/>
                                                                            <input id={profile.profileNumber} type="file" name={profile.profileNumber} onChange={this.handleFileChange} style={{display: 'none'}}/>
                                                                        </label>    
                                                                        {/* <label>
                                                                            <input type="checkbox" checked={true} onChange={()=>{}} disabled={true}/>
                                                                        </label> */}
                                                                </form> 
                                                            )   
                                                        })
                                                        }
                                                    </td>
                                                    <td>
                                                        <button type="button" value={index} 
                                                            onClick={()=>this.saveDocumentsProfiles(this.state.orderNumber, material.orderPosition)} 
                                                            id={'saveButton' + index} className="btn btn-success" 
                                                            hidden={this.state.orderPositionSelected != material.orderPosition} 
                                                            style={{ padding: 0 }} 
                                                            disabled={this.state.deliveryNoteNumber == '' && this.state.defaultDeliveryNoteNumber == ''}><FormattedMessage id="uploadDocuments.saveButton" />
                                                        </button>
                                                    </td>
                                                </tr> 
                                            )
                                            
                                        })}
                                    </tbody>                                  
                                </table>
                            </div>
                        </div>


                        {/* <div className="card" style={{ padding: 20, marginTop: 30 }}>
                            <div className="table-responsive">
                                <table className="table table-hover" style={{ marginTop: 20 }}>
                                    <thead>
                                        <tr>
                                            <th scope="col"><FormattedMessage id="showCertificate.formTableOrderNumberTh" /></th>
                                            <th scope="col"><FormattedMessage id="showCertificate.formTableDeliveryNumberTh" /></th>
                                            <th scope="col"><FormattedMessage id="showCertificate.formTableMaterialQtyTh" /></th>
                                            <th scope="col"><FormattedMessage id="showCertificate.formTableCreationDteTh" /></th>
                                            <th scope="col" />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {allItemRows}
                                    </tbody>
                                </table>
                            </div>
                        </div> */}
                        {/* </form> */}
                        {/* <div className="alert alert-danger" role="alert" hidden={!this.state.decryptError}>
                            <FormattedMessage id="showCertificate.errorDecryption" />
                        </div> */}
                    </div>
                }
                <div>
                    <LoadingComponent hidden={this.state.showLoadingModal} />
                </div> 
                {/* <div>
                    <Modal animation={false} show={this.state.showAPIErrorModal}>
                        <ModalHeader>
                            <Modal.Title><FormattedMessage id="uploadDocuments.uiErrorMessageFailTitleAPICall" /></Modal.Title>
                        </ModalHeader>    
                        <Modal.Body>
                            <div>{this.state.apiErrorMessage}</div>
                        </Modal.Body>    
                        <Modal.Footer>
                            <Button 
                            onClick={()=>{this.setState({showAPIErrorModal: false})}}><FormattedMessage id="uploadDocuments.closeButton" /></Button>
                        </Modal.Footer>                
                    </Modal>
                </div>          */}
                <div>
                    <Modal animation={false} show={this.state.showModal}>
                        <ModalHeader>
                            <Modal.Title><FormattedMessage id="showCertificate.modalTitle" /></Modal.Title>
                        </ModalHeader>
                        <Modal.Body>
                            <div className="card" style={{ padding: 20, marginTop: 5 }} id="pdfSupplierConsigneeONumberId">
                                <div className="form-row">
                                    <div className="form-group col-md-7 col-sm-12">
                                        <h4><FormattedMessage id="showCertificate.modalSupplier" /></h4>
                                        <p style={{ marginTop: 20 }} id="pdfSupplierInfo">
                                            {this.state.supplierData.name}<br />
                                            {this.state.supplierData.street}<br />
                                            {this.state.supplierData.zip}&nbsp;{this.state.supplierData.city}<br />
                                            {this.state.supplierData.country}<br />

                                        </p>
                                    </div>
                                    <div className="form-group col-md-5 col-sm-12">
                                        <h4><FormattedMessage id="showCertificate.modalConsignee" /></h4>
                                        <p style={{ marginTop: 20 }} id="pdfConsigneeInfo">
                                            {this.state.consigneeData.name}<br />
                                            {this.state.consigneeData.street}<br />
                                            {this.state.consigneeData.zip}&nbsp;{this.state.consigneeData.city}<br />
                                            {this.state.consigneeData.country}
                                        </p>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-md-7 col-sm-12">
                                        <label htmlFor="orderNumberInput"><h4><FormattedMessage id="showCertificate.modalOrderNumber" /></h4></label>
                                        <input name="orderNumber" value={this.state.orderNumber} type="text" className="form-control-plaintext" id="orderNumberInput" readOnly />
                                    </div>
                                    <div className="form-group col-md-5 col-sm-12">
                                        <label htmlFor="deliveryNumberInput"><h4><FormattedMessage id="showCertificate.modalDeliveryNumber" /></h4></label>
                                        <input name="deliveryNumber" value={this.state.deliveryNumber} type="text" className="form-control-plaintext" id="deliveryNumberInput" readOnly />
                                    </div>
                                    <div className="form-group col-md-7 col-sm-12">
                                        <label htmlFor="linkURLLabel"><h4>URL</h4></label>
                                        <input name="linkurl" value={window.location.origin + "/certNumber/" + this.state.orderNumber + "/" + this.state.orderPosition + "/" + this.state.deliveryNumber} type="text" className="form-control-plaintext" id="urloutput" readOnly />
                                    </div>
                                </div>
                            </div>



                            <div className="card" style={{ padding: 20, marginTop: 30 }} id="pdfFooterId">
                                <div className="form-row">
                                    <div className="form-group col-lg-10 col-md-8 col-sm-12">
                                        <label htmlFor="nameInspectorInput"><h6><FormattedMessage id="showCertificate.modalInspectorName" /></h6></label>
                                        <input name="nameInspector" value={this.state.nameInspector} type="text" className="form-control-plaintext" id="nameInspectorInput" readOnly />
                                    </div>
                                    <div className="form-group col-lg-2 col-md-4 col-sm-12">
                                        <label htmlFor="inspectionDateInput"><h6><FormattedMessage id="showCertificate.modalInspectionDate" /></h6></label>
                                        <input name="inspectionDate" value={moment.unix(this.state.inspectionDate).format("DD/MM/YYYY")} className="form-control-plaintext" id="inspectionDateInput" readOnly />
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="form-row" style={{ marginTop: 20 }}>
                                        <div className="form-group col-12 d-flex align-items-center justify-content-center">
                                            <h4><FormattedMessage id="showCertificate.modalNoteTestsResultsTitle" /></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="card" style={{ marginTop: 10 }}>
                                    <div className="form-row" style={{ marginTop: 20 }}>
                                        <div className="form-group col-10 d-flex align-items-center justify-content-center">
                                            <div className="text-justify" style={{ paddingLeft: 30 }} id="noteTestsText">
                                                <FormattedMessage id="showCertificate.modalNoteTestsResultsText" />
                                            </div>
                                        </div>
                                        <div className="form-group col-2 d-flex align-items-center justify-content-center">
                                            <input type="checkbox" disabled="disabled" checked={this.state.confirmation} name="noteTestResults" className="form-control-plaintext" id="noteTestResultsCheckBox" style={{ width: 20, height: 20 }} readOnly />
                                        </div>
                                    </div>
                                </div>
                                <div className="card" style={{ marginTop: 10 }}>
                                    <div className="form-row" style={{ marginTop: 25 }}>
                                        <div className="form-group col-12 d-flex align-items-center justify-content-center">
                                            <h4><FormattedMessage id="showCertificate.modalConfirmationTitle" /></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="card" style={{ marginTop: 10 }}>
                                    <div className="form-row" style={{ marginTop: 25 }}>
                                        <div className="form-group col-md-10 col-sm-12 d-flex align-items-center justify-content-center">
                                            <div className="text-justify" style={{ paddingLeft: 20, paddingRight: 20 }} id="confirmationText">
                                                <FormattedMessage id="showCertificate.modalConfirmationText" />
                                            </div>
                                        </div>
                                        <div className="form-group col-md-2 col-sm-12  d-flex align-items-center justify-content-center">
                                            <input type="checkbox" name="confirmation" disabled="disabled" checked={this.state.confirmation} className="form-control-plaintext" id="confirmationCheckBox" style={{ width: 20, height: 20 }} readOnly />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            {isSupplier &&
                                <LinkContainer to={{ pathname: "/create-certificate", state: this.state }}>
                                    <Button className="btn btn-warning"><FormattedMessage id="showCertificate.btnEditCert" /></Button>
                                </LinkContainer>
                            }
                            <Button className="btn btn-info" onClick={this.generatePDF}><FormattedMessage id="showCertificate.modalGeneratePDFBtn" /></Button>
                            <Button onClick={this.handleClose}><FormattedMessage id="showCertificate.modalCloseBtn" /></Button>
                        </Modal.Footer>
                    </Modal>  
                </div>
                <Footer />
            </div >
        );
    }
}

export default UploadDocumentsContainer
