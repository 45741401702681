import './resources/css/index.css';
import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import AppContainer from './containers/AppContainer';
import CreateCertificateContainer from './containers/CreateCertificateContainer';
import ShowCertificatesContainer from './containers/ShowCertificatesContainer';
import CreateUserContainer from './containers/CreateUserContainer';
import ProfileContainer from './containers/ProfileContainer';
import RetrievePendingCompanies from './containers/RetrievePendingCompanies';
import UploadDocumentsContainer from './containers/UploadDocumentsContainer';

import { addLocaleData, IntlProvider } from 'react-intl';
import en from 'react-intl/locale-data/en';
import de from 'react-intl/locale-data/de';
import pt from 'react-intl/locale-data/pt';

import { flattenMessages } from './utils';

import messages from './messages';
import { UserService } from './services/UserService';
import RefLinkComponent from './components/RefLinkComponent';
import SAPdocuRedirectComponent from './components/SAPdocuRedirectComponent';

import LogoffComponent from './components/LogoffComponent';
import OidcProvider from './services/OidcProvider';
//import { useParams } from 'react-router';

addLocaleData([...en, ...de, ...pt]);

const setLanguage = () => {
    localStorage.setItem('language', 'en-US');
    return 'en-US';
};

let locale = localStorage.getItem('language') ? localStorage.getItem('language') : setLanguage();

const App = () => {
    const userService = userService == null ? new UserService() : userService;
    const userRole = userService.getUserRole();
    const params = useParams();
  
    const [userLoad, setUserLoad] = useState();
    const [isNewUser, setIsNewUser] = useState();
    const [isNewUserForSimens, setIsNewUserForSimens] = useState(null);
    const [isAdmin, setIsAdmin] = useState(null);
    const [isAdminOrSupplier, setIsAdminOrSupplier] = useState(null);
    const [isAdminOrSupplierLoggedIn, setIsAdminOrSupplierLoggedIn] = useState();
    const [isSupplierLoggedIn, setIsSupplierLoggedIn] = useState();
    const [isAdminLoggedIn, setIsAdminLoggedIn] = useState();
    const [isNotLoggedIn, setIsNotLoggedIn] = useState(null);
    const [isSiemensLoggedIn, setIsSiemensLoggedIn] = useState();

    let navigate = useNavigate();

    useEffect(() => {
        if (userLoad || localStorage.getItem('oidc_user')) {
            const user = JSON.parse(localStorage.getItem('oidc_user'));

            if (user.created) {
                setIsAdminOrSupplierLoggedIn(true);
            } else {
                if ((user.profile["sub"].includes("Siemens"))) {
                    setIsNewUserForSimens(true);   
                    navigate("/create-siemens", { replace: true });
                } else {
                    setIsNewUser(true);
                    navigate("/create-supplier", { replace: true });
                }
            }

            if (user.approved) {
                switch (user.role) {
                    case 'userSiemens':
                        setIsSiemensLoggedIn(true);
                        break; 
                    case 'userSupplier':
                        setIsSupplierLoggedIn(true);
                        break; 
                    case 'userAdmin':
                        setIsAdminLoggedIn(true);
                        break; 
                }
            }

        }
    }, [userLoad]);



    return (
        <div>
            <IntlProvider locale={locale} messages={flattenMessages(messages[locale])}>
                <OidcProvider setUserLoad={setUserLoad}>
                    <div>
                        <Routes>

                            {isAdminOrSupplierLoggedIn &&
                                <Route path="/" element={<AppContainer />} />
                            }
                            {isNewUser &&
                                <Route path="/" element={<CreateUserContainer />} />
                            }

                            
                            <Route path="/create-supplier" element={<CreateUserContainer type='supplier' />} />
                        
                            <Route path="/create-siemens" element={<CreateUserContainer type='siemens' />} />
                            
                            {(isAdminOrSupplierLoggedIn|| isSiemensLoggedIn) &&
                                <Route path="/create-certificate" element={<CreateCertificateContainer />} />
                            }
                            {(isAdminOrSupplierLoggedIn|| isSiemensLoggedIn) &&
                                <Route path="/show-certificate" element={<ShowCertificatesContainer />} />
                            }

                            {(isAdminOrSupplierLoggedIn|| isSiemensLoggedIn) &&
                                <Route path="/upload-documents" element={<UploadDocumentsContainer />} />
                            }
{/*                             {isAdminLoggedIn &&
                                <Route path="/create-consignee" component={CreateConsigneeContainer} />
                            } */}
                            {isAdminLoggedIn &&
                                <Route path="/retrieve-companies" element={<RetrievePendingCompanies />} />
                            }
{/*                             {isAdminLoggedIn &&
                                <Route path="/dashboard" component={CertblockDashboardContainer} />
                            } */}

                            <Route path="/logoff" element={<LogoffComponent />} />
                            <Route path="/profile" element={<ProfileContainer />} />
                            {/* <Route path="/setEncryptedKey/:encryptedKey" component={EncryptedKeyComponent} /> */}

                            <Route path="/certNumber/:orderNumber/:orderPosition/:deliveryNumber" element={<RefLinkComponent />} /> 

                            <Route path="/document/:orderNumber/:orderPosition/:deliveryNumber/:documentProfileId" element={<SAPdocuRedirectComponent />} /> 

                        </Routes>
                    </div>
                </OidcProvider>
            </IntlProvider>
        </div>
    )

}

ReactDOM.render(
    <React.Fragment>
        <Router>
            <App />
        </Router>
    </React.Fragment>,
    document.getElementById('root')
);
