import { CertificateApi, IAMApi, Configuration } from "@certblock/api";
import axios from 'axios';
import OidcProvider from './OidcProvider';

const BASEPATH = window.ApiCertblock.apiUrl;
const APICONFIG = window.ApiConfig;

export class ApiService {

    constructor() {
        
    }

    getIAM_API(jwt) {

        const CONF = new Configuration({ apiKey: jwt, basePath: BASEPATH }); 
        const IAM_API= new IAMApi(CONF);
        
        return IAM_API;
    }

    getCRT_API(jwt) {

        const CONF = new Configuration({ apiKey: jwt, basePath: BASEPATH }); 
        const CRT_API = new CertificateApi(CONF);
        
        return CRT_API;

    }

    async callApi(endpoint = '', params = [], callMethod = 'get', data = {}, body = {}, urlCall = '', file = null) {
        
        console.log(`DEBUG [ApiService callApi] 
            (endpoint, params, callMethod, data, body, urlCall, file): 
            (${endpoint}, ${params}, ${callMethod},  ${data}, ${body}), ${urlCall} , ${file}`);

        const oidc = new OidcProvider();
        const user = oidc.getUser();
        let url = ''
        if (endpoint === 'suppliernumber' && params[0] === 'email') {
            url = `${APICONFIG.apiUrl}/suppliers?ident=${user.profile.email}`
        } else if (endpoint === 'orders') {
            url = `${APICONFIG.apiUrl}/supplier/${params[0]}/orders`
        } else if (endpoint === 'orderPrefix') {
            url = `${APICONFIG.apiUrl}/supplier/${params[0]}/orders/${params[1]}`
        } else if (endpoint === 'materials') {
            url = `${APICONFIG.apiUrl}/orders/${params[0]}/material`
        } else if (endpoint === 'documentProfiles') {
            // /orders/{orderNumber}/deliverynotes/{deliveryNoteNumber}/documents/{orderPosition}/documentProfiles
             url = `${APICONFIG.apiUrl}/orders/${params[0]}/deliverynotes/${params[1]}/documents/${params[2]}/documentProfiles`
        } else if (endpoint === 'documentProfile') {
            // /orders/{orderNumber}/deliverynotes/{deliveryNoteNumber}/documents/{orderPosition}/documentProfiles
             url = `${APICONFIG.apiUrl}/orders/${params[0]}/deliverynotes/${params[1]}/documents/${params[2]}/documentProfiles/${params[3]}`;
        }else if (endpoint === 'setUploadSlots') {
            // /orders/{orderNumber}/deliverynotes/{deliveryNoteNumber}/documents/{orderPosition}/upload
            url = `${APICONFIG.apiUrl}/orders/${params[0]}/deliverynotes/${params[1]}/documents/${params[2]}/upload`;
        } else if (endpoint === 'uploadFile') {
            url = urlCall;
        } else if (endpoint === 'pollingFiles'){
            // /orders/{orderNumber}/deliverynotes/{deliveryNoteNumber}/documents/{orderPosition}/upload/{slotId}
            url = `${APICONFIG.apiUrl}/orders/${params[0]}/deliverynotes/${params[1]}/documents/${params[2]}/upload/${params[3]}`;
        } else if (endpoint === 'profileDocsAssignment') {
            // /orders/{orderNumber}/deliverynotes/{deliveryNoteNumber}/documents/{orderPosition}/documentProfiles/{docuProfileId}
            url = url = `${APICONFIG.apiUrl}/orders/${params[0]}/deliverynotes/${params[1]}/documents/${params[2]}/documentProfiles/${params[3]}`;
        }

        if (user === null || !user.profile.email) {
            console.warn("User not authenticated. Unable to get API data");
            return null;
        } 

        var request;
        if (endpoint !== 'uploadFile')  { 
            request = axios({
                headers: {
                    'Authorization': user.id_token
                },
                method: callMethod, 
                url: url , 
                data: data, 
                body: body,
            }).catch(error => {
                console.log(`DEBUG [ApiService callApi] ERROR:`);
                console.log(JSON.stringify(error));
                throw new Error (error.message);
            });
        }

        if (endpoint === 'uploadFile') {
            request = axios({
                headers: {
                     'x-amz-server-side-encryption' : 'aws:kms'
                },
                method: callMethod, 
                url: url,
                data: file,
            }).catch(error => {
                console.log(`DEBUG [ApiService callApi] Error:`);
                console.log(JSON.stringify(error));
                throw new Error (error.message);
            });
        }   
        return request;
    }

    async getMaterials(value, callback) {
        try {
            let response = (await this.callApi('materials', [value])).data;
            callback(response.map(p => {
                // console.log(p);
                return {
                    // orderPosition: p.EBELP.replace(/^0+(?!\.|$)/, ''),
                    orderPosition: p.EBELP,
                    orderNumber: p.EBELN,
                    materialNumber: p.EMATN,
                    materialDescription: p.TXZ01,
                    quantity: p.BSTMG
                };
            }))
        }catch (error) {
            console.log(JSON.stringify(error));
            throw new Error (error.message);
        }
    }

    async getDocumentProfiles(material,orderPosition, deliveryNoteNumber, callback){
        console.log(`DEBUG [ApiService getDocumentProfiles] material: ${material} orderPosition:${orderPosition}`);
        try {
            let response = (await this.callApi('documentProfiles',[material, deliveryNoteNumber, orderPosition ])).data;
            // console.log(response);
            callback(response.map((p,idx) => {
                return {
                    profileNumber: p,
                    index: idx
                };
            }))
        }catch(error) {
            console.log(JSON.stringify(error));
            throw new Error (error.message);
        }

    }    

}

export default ApiService
